const offers =

{
  "apparel": [{
    "srNo": "1",
    "colSpan": 7,
    "offerPeriod": "1 Sept 2024 to 30 Sept 2024",
    "type": "All Apparel products ",
    "location": "All cities ",
    "mov": "5,000",
    "mxov": "14,999",
    "discount": "3%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF3",
    "Details": "Flat 3% off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "mxov": "48,999",
    "discount": "4%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF4",
    "Details": "Flat 4% off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "49,000",
    "mxov": "74,999",
    "discount": "5%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF5",
    "Details": "Flat 5% off on orders worth Rs. 49,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "75,000",
    "mxov": "99,999",
    "discount": "6%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF6",
    "Details": "Flat 6% off on orders worth Rs. 75,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "100,000",
    "mxov": "149,999",
    "discount": "7%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF7",
    "Details": "Flat 7% off on orders worth Rs. 1,00,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "150,000",
    "mxov": "Unlimited",
    "discount": "8%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF8",
    "Details": "Flat 8% off on orders worth Rs. 1,50,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "250,000",
    "mxov": "Unlimited",
    "discount": "9%",
    "noOfCoupons": "Unlimited",
    "code": "JFFAF9",
    "Details": "Flat 9% off on orders worth Rs. 2,50,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  }
  ],

  "footwear": [{
    "srNo": "1",
    "colSpan": 7,
    "offerPeriod": "1 Sept 2024 to 30 Sept 2024",
    "type": "All footwear products ",
    "location": "All cities ",
    "mov": "5,000",
    "mxov": "14,999",
    "discount": "1%",
    "noOfCoupons": "Unlimited",
    "code": "JFC1",
    "Details": "Flat 1% off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "15,000",
    "mxov": "48,999",
    "discount": "2%",
    "noOfCoupons": "Unlimited",
    "code": "JFC2",
    "Details": "Flat 2% off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "49,000",
    "mxov": "74,999",
    "discount": "4%",
    "noOfCoupons": "Unlimited",
    "code": "JFC4",
    "Details": "Flat 4% off on orders worth Rs. 49,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "75,000",
    "mxov": "149,999",
    "discount": "5%",
    "noOfCoupons": "Unlimited",
    "code": "JFC5",
    "Details": "Flat 5% off on orders worth Rs. 75,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "150,000",
    "mxov": "249,999",
    "discount": "6%",
    "noOfCoupons": "Unlimited",
    "code": "JFC6",
    "Details": "Flat 6% off on orders worth Rs. 1,50,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "mov": "250,000",
    "mxov": "Unlimited",
    "discount": "7%",
    "noOfCoupons": "Unlimited",
    "code": "JFC7",
    "Details": "Flat 7% off on orders worth Rs. 2,50,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  }
  ],

  "fmcg": [{
    "offerPeriod": "2-7, 9-14, 16-21, 23-28 Sept 2024",
    "type": "All Grocery",
    "location": "Bangalore",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRBLALL50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-7, 9-14, 16-21, 23-28 Sept 2024",
    "type": "All Grocery",
    "location": "Chennai",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRCHALL50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-7, 9-14, 16-21, 23-28 Sept 2024",
    "type": "All Grocery",
    "location": "Delhi",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRDLALL50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-7, 9-14, 16-21, 23-28 Sept 2024",
    "type": "All Grocery",
    "location": "Hyderabad",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRHYALL50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-7, 9-14, 16-21, 23-28 Sept 2024",
    "type": "All Grocery",
    "location": "Gurugram",
    "mov": "5,000",
    "discount": "50",
    "noOfCoupons": "Unlimited",
    "code": "GRGGALL50",
    "Details": "Rs.50 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 16, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 16, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 16, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "325",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Currently Not Scheduled",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "5,000",
    "discount": "55",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR55",
    "Details": "Rs.55 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Currently Not Scheduled",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Currently Not Scheduled",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "203",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR203",
    "Details": "Rs.203 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date.",
    "subDetails": ""
  },
  {
    "offerPeriod": "Currently Not Scheduled",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date.",
    "subDetails": ""
  },
  {
    "offerPeriod": "3, 6, 10, 13, 17, 20, 24, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 17, 20, 24, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Bangalore",
    "mov": "30,000",
    "discount": "630",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFLR630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "9, 12, 14, 2, 5, 7, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "9, 12, 14, 2, 5, 7, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "325",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 25 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad",
    "mov": "5,000",
    "discount": "55",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR55",
    "Details": "Rs.55 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 25 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 25 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "203",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR203",
    "Details": "Rs.203 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date.",
    "subDetails": ""
  },
  {
    "offerPeriod": "4, 11, 25 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad ",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date.",
    "subDetails": ""
  },
  {
    "offerPeriod": "3, 6, 10, 13,18, 20, 24, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13,18, 20, 24, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Hyderabad",
    "mov": "30,000",
    "discount": "630",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFLR630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 10, 11, 12, 14, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024 ",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 10, 11, 12, 14, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR195",
    "Details": "Rs. 195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 10, 11, 12, 14, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "325",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3,6, 9, 13, 16, 20, 24, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "5,000",
    "discount": "55",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR55",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3,6, 9, 13, 16, 20, 24, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3,6, 9, 13, 16, 20, 24, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "203",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR203",
    "Details": "Rs. 203 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3,6, 9, 13, 16, 20, 24, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "17-Sep-24",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "17-Sep-24",
    "type": "Atta and Flours",
    "location": "Delhi",
    "mov": "30,000",
    "discount": "630",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFLR630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 18, 19, 21, 24, 26, 28 Sept 2024 ",
    "type": "Atta and Flours",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 18, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Atta and Flours",
    "location": "Gurgaon, ",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR195",
    "Details": "Rs. 195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 18, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Atta and Flours",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "325",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2,4, 6, 9, 11, 13, 16, 20, 23, 25, 27, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Gurgaon",
    "mov": "5,000",
    "discount": "55",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR55",
    "Details": "Rs.55 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2,4, 6, 9, 11, 13, 16, 20, 23, 25, 27, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2,4, 6, 9, 11, 13, 16, 20, 23, 25, 27, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "203",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR203",
    "Details": "Rs. 203 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2,4, 6, 9, 11, 13, 16, 20, 23, 25, 27, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "17-Sep-24",
    "type": "Atta and Flours",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "17-Sep-24",
    "type": "Atta and Flours",
    "location": "Gurgaon",
    "mov": "30,000",
    "discount": "630",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFLR630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3-5, 7, 10-12, 14, 17, 18, 19, 21, 24-26, 28 Sept 2024 ",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3-5, 7, 10-12, 14, 17, 18, 19, 21, 24-26, 28 Sept 2024",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR195",
    "Details": "Rs. 195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3-5, 7, 10-12, 14, 17, 18, 19, 21, 24-26, 28 Sept 2024",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "325",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 16, 23, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "5,000",
    "discount": "55",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR55",
    "Details": "Rs.55 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 16, 23, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "120",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR120",
    "Details": "Rs.120 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 16, 23, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "203",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR203",
    "Details": "Rs. 203 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 16, 23, 30 Sept 2024",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 20, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR450",
    "Details": "Rs.450 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 20, 27 Sept 2024",
    "type": "Atta and Flours",
    "location": "Chennai",
    "mov": "30,000",
    "discount": "630",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFLR630",
    "Details": "Rs.630 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 16, 19, 21, 24, 26 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore ",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 16, 19, 21, 24, 26 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 16, 19, 21, 24, 26 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024 ",
    "type": "Dal and Pulses",
    "location": "Bangalore",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 17, 20, 23, 27, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 17, 20, 23, 27, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL725",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 17, 20, 23, 27, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Bangalore",
    "mov": "35,000",
    "discount": "1015",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDAL1015",
    "Details": "Rs.1015 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 17, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 17, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 17, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024 ",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 16, 20, 23, 27, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 16, 20, 23, 27, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL725",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 16, 20, 23, 27, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Hyderabad",
    "mov": "35,000",
    "discount": "1015",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDAL1015",
    "Details": "Rs.1015 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL300",
    "Details": "Rs. 300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 6, 10, 11, 13, 17, 18, 20, 24, 25, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 6, 10, 11, 13, 17, 18, 20, 24, 25, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL330",
    "Details": "Rs. 330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 6, 10, 11, 13, 17, 18, 20, 24, 25, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Dal and Pulses",
    "location": "Delhi",
    "mov": "35,000",
    "discount": "1015",
    "noOfCoupons": "Unlimited",
    "code": "GRDLDAL1015",
    "Details": "Rs.1015 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL300",
    "Details": "Rs. 300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 11, 16 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 11, 16 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL330",
    "Details": "Rs. 330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 11, 16 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 10, 13, 17, 20, 24, 25, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 10, 13, 17, 20, 24, 25, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 10, 13, 17, 20, 24, 25, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Gurgaon",
    "mov": "35,000",
    "discount": "1015",
    "noOfCoupons": "Unlimited",
    "code": "GRGGDAL1015",
    "Details": "Rs.1015 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 11, 12, 14, 19, 29, 24, 26, 28 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 11, 12, 14, 19, 29, 24, 26, 28 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL300",
    "Details": "Rs. 300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 11, 12, 14, 19, 29, 24, 26, 28 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "575",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL575",
    "Details": "Rs.575 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 16, 18, 23, 25, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 16, 18, 23, 25, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL330",
    "Details": "Rs. 330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 16, 18, 23, 25, 30 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 17, 20, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 17, 20, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 17, 20, 27 Sept 2024",
    "type": "Dal and Pulses",
    "location": "Chennai",
    "mov": "35,000",
    "discount": "1015",
    "noOfCoupons": "Unlimited",
    "code": "GRCHDAL1015",
    "Details": "Rs.1015 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Sept 2-6, 9-13, 16, 17, 20, 23-27, 30 Sept 24",
    "type": "Dry Fruit and Spices",
    "location": "Bangalore",
    "mov": "1,000",
    "discount": "20",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDFS20",
    "Details": "Rs.20 off on orders worth Rs. 1,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Sept 2-6, 9-13, 16, 17, 20, 23-27, 30 Sept 24",
    "type": "Dry Fruit and Spices",
    "location": "Bangalore",
    "mov": "2,000",
    "discount": "45",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDFS45",
    "Details": "Rs.45 off on orders worth Rs. 2,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Sept 2-6, 9-13, 16, 17, 20, 23-27, 30 Sept 24",
    "type": "Dry Fruit and Spices",
    "location": "Bangalore",
    "mov": "3,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRBLDFS75",
    "Details": "Rs.75 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 20, 23-27, 30 Sept 2024 ",
    "type": "Dry Fruit and Spices",
    "location": "Hyderabad",
    "mov": "1,000",
    "discount": "20",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDFS20",
    "Details": "Rs.20 off on orders worth Rs. 1,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 20, 23-27, 30 Sept 2024",
    "type": "Dry Fruit and Spices",
    "location": "Hyderabad",
    "mov": "2,000",
    "discount": "45",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDFS45",
    "Details": "Rs.45 off on orders worth Rs. 2,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 20, 23-27, 30 Sept 2024",
    "type": "Dry Fruit and Spices",
    "location": "Hyderabad",
    "mov": "3,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRHYDFS75",
    "Details": "Rs.75 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7,10, 12, 14, 16, 19, 21, 24, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7,10, 12, 14, 16, 19, 21, 24, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7,10, 12, 14, 16, 19, 21, 24, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7,10, 12, 14, 16, 19, 21, 24, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 17, 18, 25 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 17, 18, 25 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 17, 18, 25 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC90",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 17, 18, 25 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 17, 18, 25 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 20, 23, 27, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 20, 23, 27, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRBLFMC725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 1, 14, 21, 24, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 1, 14, 21, 24, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 1, 14, 21, 24, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 1, 14, 21, 24, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 18, 23, 25, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 18, 23, 25, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 18, 23, 25, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 18, 23, 25, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 18, 23, 25, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRHYFMC725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2,  4, 5, 7, 9, 11, 12, 14, 17, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2,  4, 5, 7, 9, 11, 12, 14, 17, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2,  4, 5, 7, 9, 11, 12, 14, 17, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2,  4, 5, 7, 9, 11, 12, 14, 17, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 10, 16, 24 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 10, 16, 24 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 10, 16, 24 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 10, 16, 24 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 10, 16, 24 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRDLFMC725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 17, 18, 19, 21, 25, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 17, 18, 19, 21, 25, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 17, 18, 19, 21, 25, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 17, 18, 19, 21, 25, 26, 28 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 20, 24, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 20, 24, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRGGFMC725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "3,000",
    "discount": "42",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC42",
    "Details": "Rs.42 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "170",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC170",
    "Details": "Rs.170 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC300",
    "Details": "Rs.300 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "3,000",
    "discount": "51",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC51",
    "Details": "Rs.51 off on orders worth Rs. 3,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "5,000",
    "discount": "90",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC90",
    "Details": "Rs.90 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "190",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC190",
    "Details": "Rs.190 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "330",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC330",
    "Details": "Rs.330 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 20, 27 Sept 2024",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "625",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC625",
    "Details": "Rs.625 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "13-Sep-24",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC375",
    "Details": "Rs.375 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "13-Sep-24",
    "type": "FMCG Foods and non Food Products",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "725",
    "noOfCoupons": "Unlimited",
    "code": "GRCHFMC725",
    "Details": "Rs.725 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9,11, 12, 16, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOLG105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9,11, 12, 16, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Bangalore",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOLG140",
    "Details": "Rs.140 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9,11, 12, 16, 18, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOLG175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "260",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOLG260",
    "Details": "Rs.260 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Bangalore",
    "mov": "20,000",
    "discount": "260",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOLG260",
    "Details": "Rs.260 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "325",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOLG325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 14, 20, 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOLG375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 14, 20, 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Bangalore",
    "mov": "30,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOLG450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOLG105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Hyderabad",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOLG140",
    "Details": "Rs.140 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOLG175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "260",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOLG260",
    "Details": "Rs.260 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Hyderabad",
    "mov": "20,000",
    "discount": "260",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOLG260",
    "Details": "Rs.260 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "325",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOLG325",
    "Details": "Rs.325 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 18, 20, 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOLG375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 18, 20, 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Hyderabad",
    "mov": "30,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOLG450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "70",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOLG70",
    "Details": "Rs.70 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOLG105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 5, 7, 9, 11, 12, 14, 19, 21, 23, 25, 26, 28, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOLG175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "180",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOLG180",
    "Details": "Rs.180 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Delhi",
    "mov": "20,000",
    "discount": "250",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOLG250",
    "Details": "Rs.250 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "Edible Oils and Ghee",
    "location": "Delhi",
    "mov": "30,000",
    "discount": "390",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOLG390",
    "Details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 17, 20, 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOLGI375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 17, 20, 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Delhi",
    "mov": "30,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOLG450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 14, 25, 26, 28 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "70",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOLG70",
    "Details": "Rs.70 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 14, 25, 26, 28 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOLG105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 14, 25, 26, 28 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOLG 175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "180",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOLG180",
    "Details": "Rs.180 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Gurgaon",
    "mov": "20,000",
    "discount": "250",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOLG240",
    "Details": "Rs.250 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Gurgaon",
    "mov": "30,000",
    "discount": "390",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOLG390",
    "Details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 17, 20 , 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOLG 375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 17, 20 , 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Gurgaon",
    "mov": "30,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOLG450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 14, 18, 21, 25, 26, 28 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "70",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOLG70 ",
    "Details": "Rs.70 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 14, 18, 21, 25, 26, 28 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOLG105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 5, 7, 11, 12, 14, 18, 21, 25, 26, 28 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOLG175",
    "Details": "Rs.175 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "180",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOLG180",
    "Details": "Rs.180 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Chennai",
    "mov": "20,000",
    "discount": "250",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOLG240",
    "Details": "Rs.250 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 23, 30 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Chennai",
    "mov": "30,000",
    "discount": "390",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOLG390",
    "Details": "Rs.390 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 20, 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOLG375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 6, 10, 13, 20, 24, 27 Sept 2024",
    "type": "Edible Oils and Ghee",
    "location": "Chennai",
    "mov": "30,000",
    "discount": "450",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOLG450",
    "Details": "Rs.450 off on orders worth Rs. 30,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 17, 18-20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Bangalore",
    "mov": "10,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOSA105",
    "Details": "Rs.105 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 17, 18-20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOSA165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 17, 19, 20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRHYOSA105",
    "Details": "Rs.105 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 17, 19, 20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRBLOSA165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 18, 20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOSA105",
    "Details": "Rs.105 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 18, 20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRDLOSA165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 18, 20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOSA105",
    "Details": "Rs.105 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 18, 20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRGGOSA165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 18, 20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOSA105",
    "Details": "Rs.105 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 18, 20, 23-27, 30 Sept 2024",
    "type": "Edible Oils, Sugar, Atta, Flours",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRCHOSA165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "7, 16, 21, 28 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "7, 16, 21, 28 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "7, 16, 21, 28 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "7, 16, 21, 28 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "35,000",
    "discount": "560",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC560",
    "Details": "Rs.560 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 19, 24, 26 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "5,000",
    "discount": "85",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC85",
    "Details": "Rs.85 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 19, 24, 26 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "10,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC175",
    "Details": "Rs.175 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 19, 24, 26 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "278",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC278",
    "Details": "Rs.278 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 19, 24, 26 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "488",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC488",
    "Details": "Rs.488 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 19, 24, 26 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "35,000",
    "discount": "700",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC700",
    "Details": "Rs.700 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 6, 9, 17, 18, 20, 23, 25, 27, 30 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "315",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 6, 9, 17, 18, 20, 23, 25, 27, 30 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "550",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 6, 9, 17, 18, 20, 23, 25, 27, 30 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Bangalore",
    "mov": "35,000",
    "discount": "875",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRIC875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 17, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 17, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 17, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 17, 19, 21, 24, 26, 28 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "35,000",
    "discount": "560",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC560",
    "Details": "Rs.560 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "5,000",
    "discount": "85",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC85",
    "Details": "Rs.85 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "10,000",
    "discount": "175",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC175",
    "Details": "Rs.175 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "278",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC278",
    "Details": "Rs.278 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "488",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC488",
    "Details": "Rs.488 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 18, 25 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "35,000",
    "discount": "700",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC700",
    "Details": "Rs.700 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 16, 20, 23, 27, 30Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "315",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 16, 20, 23, 27, 30 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "550",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 6, 9, 13, 16, 20, 23, 27, 30 Sept 2024",
    "type": "Rice and Cereals ",
    "location": "Hyderabad",
    "mov": "35,000",
    "discount": "875",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRIC875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 5, 7, 9, 12, 14, 16, 19, 21, 23, 26, 28, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 11, 18, 24, 25 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "5,000",
    "discount": "88",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC88",
    "Details": "Rs.88 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 11, 18, 24, 25 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "10,000",
    "discount": "185",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC185",
    "Details": "Rs.185 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 11, 18, 24, 25 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "293",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC293",
    "Details": "Rs.293 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 4, 11, 18, 24, 25 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "500",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC500",
    "Details": "Rs.500 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 10, 13, 17, 20, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "315",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 10, 13, 17, 20, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "550",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 10, 13, 17, 20, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Delhi",
    "mov": "35,000",
    "discount": "875",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRIC875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 16, 19, 21, 24 26, 28 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 16, 19, 21, 24 26, 28 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC95",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 16, 19, 21, 24 26, 28 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 18, 23, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "5,000",
    "discount": "88",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC88",
    "Details": "Rs.88 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 18, 23, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "10,000",
    "discount": "185",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC85",
    "Details": "Rs.185 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 18, 23, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "293",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC293",
    "Details": "Rs.293 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 9, 18, 23, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "500",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC500",
    "Details": "Rs.500 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 13, 17, 20, 25, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "315",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 13, 17, 20, 25, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "550",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 11, 13, 17, 20, 25, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Gurgaon",
    "mov": "35,000",
    "discount": "875",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRIC875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 19, 21, 24, 26, 28 Sept 24",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "100",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC100",
    "Details": "Rs.100 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 19, 21, 24, 26, 28 Sept 24",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "195",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC195",
    "Details": "Rs.195 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "3, 5, 7, 10, 12, 14, 19, 21, 24, 26, 28 Sept 24",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "375",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC375",
    "Details": "Rs.375 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 16, 18, 23, 25, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "5,000",
    "discount": "88",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC88",
    "Details": "Rs.88 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 16, 18, 23, 25, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "10,000",
    "discount": "185",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC185",
    "Details": "Rs.185 off on orders worth Rs. 10,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 16, 18, 23, 25, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "293",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC293",
    "Details": "Rs.293 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 4, 9, 11, 16, 18, 23, 25, 30 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "500",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC500",
    "Details": "Rs.500 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 17, 20, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "315",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC315",
    "Details": "Rs.315 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 17, 20, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "550",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC550",
    "Details": "Rs.550 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "6, 13, 17, 20, 27 Sept 2024",
    "type": "Rice and Cereals",
    "location": "Chennai",
    "mov": "35,000",
    "discount": "875",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRIC875",
    "Details": "Rs.875 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 19, 18-20, 23-27, 30 Sept 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Bangalore",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRBLRND75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 17, 18-20, 23-27, 30 Sept 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Hyderabad",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRHYRND75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 18, 20, 23-27, 30 Sept 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Delhi",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRDLRND75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16, 18, 21, 23-27, 30 Sept 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Gurgaon",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRGGRND75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2-6, 9-13, 16 17, 20, 23-27, 30 Sept 2024",
    "type": "Rice, Cereals, Dals, Pulses",
    "location": "Chennai",
    "mov": "5,000",
    "discount": "75",
    "noOfCoupons": "Unlimited",
    "code": "GRCHRND75",
    "Details": "Rs.75 off on orders worth Rs. 5,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 14, 19, 23, 24, 26 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore",
    "mov": "12,000",
    "discount": "84",
    "noOfCoupons": "Unlimited",
    "code": "GRBLSUG84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 14, 19, 23, 24, 26 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRBLSUG105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 14, 19, 23, 24, 26 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GRBLSUG147",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "10, 12, 17 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRBLSUG165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "10, 12, 17 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRBLSUG300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "10, 12, 17 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore",
    "mov": "35,000",
    "discount": "455",
    "noOfCoupons": "Unlimited",
    "code": "GRBLSUG455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 13, 18, 20, 25, 27 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore",
    "mov": "25,000",
    "discount": "338",
    "noOfCoupons": "Unlimited",
    "code": "GRBLSUG338",
    "Details": "Rs.338 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 13, 18, 20, 25, 27 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Bangalore",
    "mov": "35,000",
    "discount": "525",
    "noOfCoupons": "Unlimited",
    "code": "GRBLSUG450",
    "Details": "Rs.525 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 9, 10, 12, 17, 21, 23, 24, 26, 30 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Hyderabad",
    "mov": "12,000",
    "discount": "84",
    "noOfCoupons": "Unlimited",
    "code": "GRHYSUG84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 9, 10, 12, 17, 21, 23, 24, 26, 30 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRHYSUG105",
    "Details": "Rs.105 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 9, 10, 12, 17, 21, 23, 24, 26, 30 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Hyderabad",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GRHYSUG147",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "13-Sep-24",
    "type": "SUGAR & Jaggery",
    "location": "Hyderabad",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRHYSUG165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "13-Sep-24",
    "type": "SUGAR & Jaggery",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRHYSUG300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "13-Sep-24",
    "type": "SUGAR & Jaggery",
    "location": "Hyderabad",
    "mov": "35,000",
    "discount": "455",
    "noOfCoupons": "Unlimited",
    "code": "GRHYSUG455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 18, 20, 25, 27 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Hyderabad",
    "mov": "25,000",
    "discount": "338",
    "noOfCoupons": "Unlimited",
    "code": "GRHYSUG338",
    "Details": "Rs.338 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 18, 20, 25, 27 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Hyderabad",
    "mov": "35,000",
    "discount": "525",
    "noOfCoupons": "Unlimited",
    "code": "GRHYSUG525",
    "Details": "Rs.525 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Delhi",
    "mov": "12,000",
    "discount": "84",
    "noOfCoupons": "Unlimited",
    "code": "GRDLSUG84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRDLSUG105",
    "Details": "Rs.105 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Delhi",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GRDLSUG140",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Delhi",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRDLSUG165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRDLSUG300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Delhi",
    "mov": "35,000",
    "discount": "455",
    "noOfCoupons": "Unlimited",
    "code": "GRDLSUG455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 13, 18,20, 25, 27 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi",
    "mov": "25,000",
    "discount": "338",
    "noOfCoupons": "Unlimited",
    "code": "GRDLSUG338",
    "Details": "Rs.338 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 13, 18,20, 25, 27 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Delhi",
    "mov": "35,000",
    "discount": "525",
    "noOfCoupons": "Unlimited",
    "code": "GRDLSUG525",
    "Details": "Rs.525 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Gurgaon",
    "mov": "12,000",
    "discount": "84",
    "noOfCoupons": "Unlimited",
    "code": "GRGGSUG84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRGGSUG105",
    "Details": "Rs.105 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Gurgaon",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GRGGSUG140",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Gurgaon",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRGGSUG165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRGGSUG300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Gurgaon",
    "mov": "35,000",
    "discount": "455",
    "noOfCoupons": "Unlimited",
    "code": "GRGGSUG455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 13, 18,20, 25, 27 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Gurgaon",
    "mov": "25,000",
    "discount": "338",
    "noOfCoupons": "Unlimited",
    "code": "GRGGSUG338",
    "Details": "Rs.338 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 13, 18,20, 25, 27 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Gurgaon",
    "mov": "35,000",
    "discount": "525",
    "noOfCoupons": "Unlimited",
    "code": "GRGGSUG525",
    "Details": "Rs.525 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 9, 10, 12, 16, 19, 23, 24, 26, 30 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Chennai",
    "mov": "12,000",
    "discount": "84",
    "noOfCoupons": "Unlimited",
    "code": "GRCHSUG84",
    "Details": "Rs.84 off on orders worth Rs. 12,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 9, 10, 12, 16, 19, 23, 24, 26, 30 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "105",
    "noOfCoupons": "Unlimited",
    "code": "GRCHSUG105",
    "Details": "Rs.105 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "2, 3, 5, 9, 10, 12, 16, 19, 23, 24, 26, 30 Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Chennai",
    "mov": "20,000",
    "discount": "140",
    "noOfCoupons": "Unlimited",
    "code": "GRCHSUG140",
    "Details": "Rs.140 off on orders worth Rs. 20,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Chennai",
    "mov": "15,000",
    "discount": "165",
    "noOfCoupons": "Unlimited",
    "code": "GRCHSUG165",
    "Details": "Rs.165 off on orders worth Rs. 15,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "300",
    "noOfCoupons": "Unlimited",
    "code": "GRCHSUG300",
    "Details": "Rs.300 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "Not Scheduled Currently",
    "type": "SUGAR & Jaggery",
    "location": "Chennai",
    "mov": "35,000",
    "discount": "455",
    "noOfCoupons": "Unlimited",
    "code": "GRCHSUG455",
    "Details": "Rs.455 off on orders worth Rs. 35,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 13, 18, 20 25, 27  Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Chennai",
    "mov": "25,000",
    "discount": "338",
    "noOfCoupons": "Unlimited",
    "code": "GRCHSUG338",
    "Details": "Rs.338 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  },
  {
    "offerPeriod": "4, 6, 11, 13, 18, 20 25, 27  Sept 2024",
    "type": "SUGAR & Jaggery",
    "location": "Chennai",
    "mov": "35,000",
    "discount": "525",
    "noOfCoupons": "Unlimited",
    "code": "GRCHSUG525",
    "Details": "Rs.525 off on orders worth Rs. 25,000 or more",
    "subDetails": "Applicable for unlimited times till offer end date."
  }
  ]
}





export default offers;